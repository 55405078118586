import React from 'react'
import SEO from '../components/SEO'
import Layout from '../components/layout'

import pic11 from '../images/pic11.jpg'

const Success = props => (
  <Layout>
    <SEO title="Success page" description="Success page" />
    <div className="content">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Success!</h1>
          </header>
          <span className="image main">
            <img src={pic11} alt="" />
          </span>
          <p>Thank you for contacting us!</p>
        </div>
      </section>
    </div>
  </Layout>
);

export default Success;